import React, { lazy, Suspense } from 'react'
import { Route, Switch, Redirect } from "react-router";
import { BrowserRouter } from "react-router-dom";

const Index = lazy(() => import('./pages/App.js'))
const OpenAnAccountKr = lazy(() => import('./pages/open-an-account-kr'))
const OpenAnAccountJp = lazy(() => import('./pages/open-an-account-jp'))
const OpenAnAccountTw = lazy(() => import('./pages/open-an-account-tw'))
export default () => {
  return (
    <BrowserRouter basename="/lp-btcc-technology">
      <Switch>
        <Route exact path="/" component={Index}/>
        <Route  path="/open-an-account-kr" component={OpenAnAccountKr} />
        <Route  path="/open-an-account-jp" component={OpenAnAccountJp} />
        <Route  path="/open-an-account-tw" component={OpenAnAccountTw} />
      </Switch>
    </BrowserRouter>
  );
};
